import React, { useState } from "react";
import { useEffect } from "react";
import logo from "./../images/logo.png";

const Splash = ({ setLoading }) => {
  // State to hold the value you want to update at regular intervals
  const [counter, setCounter] = useState(0);

  useEffect(() => {
    // Start the interval when the component mounts
    var cnt = 10;
    const intervalId = setInterval(() => {
      cnt += 33;
      if (cnt >= 100) {
        setCounter(100);
        setTimeout(() => {
          setLoading(0);
        }, [1000]);
      } else {
        setCounter(cnt);
      }
    }, 50); // 1000ms = 1 second

    // Clean up the interval when the component unmounts
    return () => {
      clearInterval(intervalId);
    };
  }, []); // Empty dependency array means this effect runs only once on mount
  return (
    <div
      className="flex justify-center align-center h-[100vh] w-[100vw]"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        width: "100vw",
      }}
    >
      <div
        className="flex flex-col justify-center align-center gap-8"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          gap: "32px",
        }}
      >
        <img
          src={logo}
          className="w-[30vw] h-[30vw] mx-auto "
          alt=""
          style={{ width: "20vw", height: "20vw", margin: "0 auto" }}
        />
        <div
          className="loader w-[30vw] "
          style={{
            width: "20vw",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <div
            className={`transition-width duration-[2000ms] ease bg-[#149414] rounded h-[6px] drop-shadow-2xl`}
            style={{
              transition: "all ease 2s",
              background: "#48a3c6",
              borderRadius: "4px",
              height: "6px",
              width: counter + "%",
            }}
          ></div>
          <p
            className="text-center"
            style={{
              color: "white",
              whiteSpace: "nowrap",
              textAlign: "center",
            }}
          >
            {counter} %
          </p>
        </div>
        <p style={{ color: "white", opacity: "0.3" }}>prkskrs</p>
        {/* <p className='text-center' style={{color:'white'}}>{counter} %</p> */}
      </div>
    </div>
  );
};

export default Splash;
