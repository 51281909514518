import React from "react";

const Stats = () => {
  return (
    <div className="container">
      <h1
        className="mb-3"
        style={{
          textAlign: "center",
          color: "white",
          textShadow: "1px 1px #FFD700",
        }}
      >
        Languages, Frameworks, Tools, and Abilities
      </h1>
      <div className="d-flex flex-wrap mb-5 mt-4">
        <a href="https://nestjs.com/" title="NestJS">
          <img
            src="https://img.shields.io/badge/NestJS-E0234E?style=for-the-badge&logo=nestjs&logoColor=white"
            alt="NestJS"
          />
        </a>
        <a href="https://www.typescriptlang.org/" title="TypeScript">
          <img
            src="https://img.shields.io/badge/TypeScript-007ACC?style=for-the-badge&logo=typescript&logoColor=white"
            alt="TypeScript"
          />
        </a>
        <a href="https://nodejs.org/" title="Node.js">
          <img
            src="https://img.shields.io/badge/Node.js-339933?style=for-the-badge&logo=node.js&logoColor=white"
            alt="Node.js"
          />
        </a>
        <a href="https://expressjs.com/" title="Express.js">
          <img
            src="https://img.shields.io/badge/Express.js-000000?style=for-the-badge&logo=express&logoColor=white"
            alt="Express.js"
          />
        </a>
        <a
          href="https://developer.mozilla.org/en-US/docs/Web/JavaScript"
          title="JavaScript"
        >
          <img
            src="https://img.shields.io/badge/JavaScript-F7DF1E?style=for-the-badge&logo=javascript&logoColor=black"
            alt="JavaScript"
          />
        </a>
        <a href="https://graphql.org/" title="GraphQL">
          <img
            src="https://img.shields.io/badge/GraphQL-E10098?style=for-the-badge&logo=graphql&logoColor=white"
            alt="GraphQL"
          />
        </a>
        <a href="https://www.mongodb.com/" title="MongoDB">
          <img
            src="https://img.shields.io/badge/MongoDB-4EA94B?style=for-the-badge&logo=mongodb&logoColor=white"
            alt="MongoDB"
          />
        </a>
        <a href="https://en.wikipedia.org/wiki/SQL" title="SQL">
          <img
            src="https://img.shields.io/badge/SQL-4479A1?style=for-the-badge&logo=postgresql&logoColor=white"
            alt="SQL"
          />
        </a>
        <a href="https://aws.amazon.com/" title="AWS">
          <img
            src="https://img.shields.io/badge/AWS-232F3E?style=for-the-badge&logo=amazon-aws&logoColor=white"
            alt="AWS"
          />
        </a>
        <a href="https://www.nginx.com/" title="Nginx">
          <img
            src="https://img.shields.io/badge/Nginx-009639?style=for-the-badge&logo=nginx&logoColor=white"
            alt="Nginx"
          />
        </a>
        <a href="https://www.djangoproject.com/" title="Django">
          <img
            src="https://img.shields.io/badge/Django-092E20?style=for-the-badge&logo=django&logoColor=white"
            alt="Django"
          />
        </a>
        <a href="https://flask.palletsprojects.com/" title="Flask">
          <img
            src="https://img.shields.io/badge/Flask-000000?style=for-the-badge&logo=flask&logoColor=white"
            alt="Flask"
          />
        </a>
        <a href="https://www.python.org/" title="Python">
          <img
            src="https://img.shields.io/badge/python-3670A0?style=for-the-badge&logo=python&logoColor=ffdd54"
            alt="Python"
          />
        </a>
        <a href="https://numpy.org/">
          <img
            src="https://img.shields.io/badge/numpy-%23013243.svg?style=for-the-badge&logo=numpy&logoColor=white"
            alt="Numpy"
          />
        </a>
        <a href="https://pandas.pydata.org/">
          <img
            src="https://img.shields.io/badge/pandas-%23150458.svg?style=for-the-badge&logo=pandas&logoColor=white"
            alt="Pandas"
          />
        </a>
        <a href="https://plotly.com/">
          <img
            src="https://img.shields.io/badge/Plotly-%233F4F75.svg?style=for-the-badge&logo=plotly&logoColor=white"
            alt="Plotly"
          />
        </a>
        <a href="https://scikit-learn.org/">
          <img
            src="https://img.shields.io/badge/scikit--learn-%23F7931E.svg?style=for-the-badge&logo=scikit-learn&logoColor=white"
            alt="Scikit Learn"
          />
        </a>
        <a href="https://www.tensorflow.org/">
          <img
            src="https://img.shields.io/badge/TensorFlow-%23FF6F00.svg?style=for-the-badge&logo=TensorFlow&logoColor=white"
            alt="Tensorflow"
          />
        </a>
      </div>

      <h1
        className="mb-3"
        style={{
          textAlign: "center",
          color: "white",
          marginTop: "100px",
          textShadow: "1px 1px #FFD700",
        }}
      >
        ⚡ Stats ⚡
      </h1>

      <div className="row">
        <div className="col-md-2"></div>
        <a
          href="https://github.com/anuraghazra/github-readme-stats"
          title="GitHub Stats"
          className="col-md-4"
        >
          <img
            className="w-100"
            style={{ width: "100%" }}
            src="https://github-readme-stats.vercel.app/api?username=prkskrs&show_icons=true&theme=react&border_color=61dafb&hide_border=true"
            alt="GitHub Stats"
          />
        </a>
        <a
          href="https://github.com/denvercoder1/github-readme-streak-stats"
          title="GitHub Streak"
          className="col-md-4"
        >
          <img
            className="w-100"
            style={{ width: "100%" }}
            src="https://github-readme-streak-stats.herokuapp.com/?user=prkskrs&theme=react&border=61dafb&hide_border=true"
            alt="GitHub Streak"
          />
        </a>
        <div className="col-md-2"></div>
        <div className="col-md-4"></div>
        <a
          href="https://github.com/anuraghazra/github-readme-stats"
          title="Top Languages"
          className="col-md-4"
        >
          <img
            className="w-100"
            style={{ width: "100%" }}
            src="https://github-readme-stats.vercel.app/api/top-langs/?username=prkskrs&title_color=61dafb&text_color=ffffff&icon_color=61dafb&bg_color=20232a&langs_count=8&layout=compact&border_color=61dafb&hide_border=true"
            alt="Top Languages"
          />
        </a>
      </div>
      <br />
      <h1
        className="my-4 mt-5"
        style={{
          textAlign: "center",
          color: "white",
          textShadow: "1px 1px #FFD700",
        }}
      >
        Dev Quotes ✍️
      </h1>
      <div className="row">
        <div className="col-md-2"></div>
        <div className="col-md-8">
          <img
            style={{ width: "100%", height: "auto" }}
            src="https://quotes-github-readme.vercel.app/api?type=horizontal&theme=radical"
            alt="Developer Quotes"
          />
        </div>
      </div>
    </div>
  );
};

export default Stats;
