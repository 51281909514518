import searchmyai from "../../images/searchmyai.png";
import inspiro from "../../images/inspiro.png";
import pms from "../../images/pms.png";
import digiayu from "../../images/digiayu.png";
import techokids from "../../images/techokids.png";
import seh from "../../images/seh.png";

export const projects = [
  {
    title: "SearchMyAI",
    description: "300+ AI Tools",
    image: searchmyai,
    image2: searchmyai,
    image3: searchmyai,
    body: "SearchMyAI is an innovative AI tool directory which serves as a centralized hub where users can effortlessly discover and explore a diverse range of AI tools curated to meet their specific needs. It build using React, Node.js, Mongo DB.\n The user interface is enriched with the visually stunning Chakra UI and Tailwind CSS, offering an aesthetically pleasing experience. With Redux, we facilitate state management, resulting in real-time updates and enhanced user interactivity.",
    technologies: [
      "TypeScript",
      "Node.js",
      "Mongo DB",
      "Firebase",
      "React",
      "Redux",
      "Tailwind",
      "Chakra UI",
    ],
    github: "#",
    deployed: "https://www.searchmyai.app/",
    bgcolor: "var(--hl2-color)",
    id: "1",
  },
  {
    title: "Inspiro",
    description: "Purchase Websites",
    image: inspiro,
    image2: inspiro,
    image3: inspiro,
    technologies: [
      "JavaScript",
      "NodeJs",
      "MongoDB",
      "DigitalOcean",
      "AWS",
      "ReactJs",
    ],
    body: "Inspiro, your gateway to curated artistic inspiration on the web. Dive into a world of meticulously crafted web pages, showcasing everything from awe-inspiring designs to the latest cutting-edge ideas. Whether you're an artist, designer, or simply an appreciator of creativity, Inspiro is your go-to platform to discover and explore the best in web-based artistry.\n The project beautifully showcased the artist's diverse creations, ranging from interactive installations to visual storytelling. This collaboration resulted in a 300% increase in online art sales and garnered recognition from leading art critics, highlighting how Inspiro transforms artistic visions into captivating digital experiences that captivate and engage audiences like never before.",
    github: "#",
    deployed: "https://inspiro.digital/",
    bgcolor: "var(--hl-color)",
    id: "2",
  },
  {
    title: "Project Management Tool",
    description: "Project Management App",
    image: pms,
    image2: pms,
    image3: pms,
    technologies: [
      "TypeScript",
      "NestJs",
      "MongoDB",
      "Redis",
      "AWS",
      "Docker",
      "NextJs",
      "React Native",
    ],
    body: "Project Management App, a dynamic solution for streamlined project organization. Just like Asana and Jira, our app is designed to efficiently manage projects, track tasks, and organize subtasks. From small teams to large enterprises, our app empowers you to stay on top of every project detail, ensuring smooth collaboration and efficient task progression.\n One project that exemplifies the capabilities of our Project Management App involved a complex product launch for a tech startup. With numerous tasks and subtasks spanning different departments, clear coordination was vital. Our app's intuitive interface allowed the team to break down the project into manageable tasks, assign responsibilities, and set deadlines. Progress tracking and real-time updates kept everyone aligned. As a result, the product was successfully launched ahead of schedule, showcasing the app's efficacy in project execution and collaboration, akin to the best in the market.",
    github: "#",
    deployed: "#",
    bgcolor: "#6c4bf4",
    id: "3",
  },
  {
    title: "DigiAyu",
    description: "Ayurved Consultancy Application",
    image: digiayu,
    image2: digiayu,
    image3: digiayu,
    technologies: [
      "TypeScript",
      "NodeJs",
      "ExpressJs",
      "MongoDB",
      "Redis",
      "Tailwind CSS",
      "SCSS",
      "ReactJs",
      "React Native",
    ],
    body: "DigiAyu is an Ayurveda online consultation platform to create a seamless and accessible platform where individuals can connect with highly trained Ayurveda practitioners and receive personalised, authentic, and effective health solutions, promoting overall wellbeing and a balanced lifestyle.\n DigiAyu stands tall as an exceptional testament to the seamless union of traditional healing and modern innovation. We are proud to witness continuous growth and exceptional analytics, solidifying DigiAyu's position as a pioneering force in the world of holistic wellness.",
    github: "#",
    deployed: "https://dev.digiayu.com/",
    bgcolor: "#f85781",
    id: "4",
  },

  {
    title: "TechoKids",
    description: "Online Learning Platform",
    image: techokids,
    image2: techokids,
    image3: techokids,
    body: "TechoKids is an online learning platform for kids of 6 to 18 years.21st-century new-age tech skills like Coding, Artificial Intelligence , Robotics , Web Development are in great demand. Kids need to learn these practical skills early on to prepare for future jobs.\n Infusing creativity with expertise, TechoKids boasts a thoughtful design, presenting a diverse range of services - from seamless Live Classes Management to Course Purchase, an immersive Online Learning Management System, Payment Integrations, and beyond. Each feature artfully woven to provide an intuitive and enriching learning experience, transforming tech education into a thrilling journey for young learners.\n TechoKids shines as an exceptional testament, igniting young minds with the brilliance of technology, shaping the innovators and visionaries of tomorrow's digital landscape.",
    technologies: [
      "JavaScript",
      "Nodejs",
      "ExpressJs",
      "MongoDB",
      "MySQL",
      "Redis",
      "SCSS",
      "Tailwind CSS",
      "ReactJS",
    ],
    github: "#",
    deployed: "https://www.techokids.com/",
    bgcolor: "#ffcc33",
    id: "1",
  },
  {
    title: "Vavo Digital",
    description: "Influencer Marketing",
    image: seh,
    image2: seh,
    image3: seh,
    technologies: [
      "TypeScript",
      "NodeJs",
      "MongoDB",
      "DigitalOcean",
      "AWS",
      "ReactJs",
    ],
    body: "Vavo, the ultimate solution for influencer marketing. With a track record of assisting more than 500 brands across 30 diverse niches, Vavo specializes in streamlining and amplifying influencer marketing efforts. Whether you're a well-established brand or a budding entrepreneur, Vavo empowers you to connect, collaborate, and grow through strategic influencer partnerships.\n This project involves the development of an influencer brand campaign website that is designed to promote a brand or product through the endorsement of an influencer. It was created  with the goal of promoting the brand and generating leads, sales, or engagement through the influencer's social media channels, personal website, or other digital platforms.",
    github: "#",
    deployed: "https://vavodigital.com/",
    bgcolor: "#3e67ff",
    id: "6",
  },
];

export default projects;
