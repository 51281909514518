import React from "react";
import { formatDistanceToNow } from "date-fns";
import { enUS } from "date-fns/locale";
import { formatText } from "../../../../utils/formatText";
import { PostCardContainer } from "./styles";

export function PostCard({ post }) {
  const { created_at, body, title, number } = post;
  console.log("post");
  console.log(post);
  const formattedDate = formatDistanceToNow(new Date(created_at), {
    locale: enUS,
    addSuffix: true,
  });
  return (
    <PostCardContainer to={`/blogs/${number}`}>
      <header style={{padding: 0, margin: 0}}>
        <h1>{title}</h1>
        <span>{formattedDate}</span>
      </header>
      <main>
        <p>{formatText(body, 80)}</p>
      </main>
    </PostCardContainer>
  );
}
