import React from "react";
import { HeaderContainer } from "./styles";

import techBg from "../../images/tech-bg.svg";

const HeaderBlogs = () => {
  return (
    <HeaderContainer>
      <img src={techBg} alt="GITHUB BLOG LOGO"></img>
    </HeaderContainer>
  );
}

export default HeaderBlogs
