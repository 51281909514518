import aboutMeImg from "../images/aboutmenew.png";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { NavLink } from "react-router-dom";
import { FiArrowUpRight } from "react-icons/fi";
import Button from "./Button";

const AboutMe = ({ name }) => {
  const [ref, inView] = useInView({
    threshold: 0.4,
    triggerOnce: true,
  });

  const staggerVariants = {
    initial: { opacity: 0 },
    animate: {
      opacity: 1,
      transition: {
        staggerChildren: 0.3,
      },
    },
  };

  const paragraphVariants = {
    initial: { y: 20, opacity: 0 },
    animate: { y: 0, opacity: 1 },
  };

  return (
    <section className="about">
      <div className="aboutContainer container">
        <div className="row">
          <motion.div
            className="personalImage col-12 col-lg-6"
            ref={ref}
            initial={{ x: "-10vw", opacity: 0, scale: 0.5 }}
            animate={
              inView
                ? { x: 0, opacity: 1, scale: 1 }
                : { x: "-10vw", opacity: 0, scale: 0.5 }
            }
            transition={{ duration: 0.4, ease: "easeInOut" }}
            whileHover={{ scale: 1.05 }}
          >
            <motion.img src={aboutMeImg} alt={name} />
          </motion.div>

          <div className="personalInfo col-12 col-lg-6">
            <motion.div className="contentContainer" variants={staggerVariants}>
              <motion.h4 variants={paragraphVariants}>
                <h1 align="left">
                  <a href="https://prkskrs.dev">
                    <img
                      style={{ width: "100%" }}
                      src="https://readme-typing-svg.herokuapp.com?center=true&lines=hey+there!+👋🏻;this+is+prkskrs....;A+passionate+backend+engineer.;Nice+to+meet+you! 👋🏻"
                      alt="titllee"
                    />
                  </a>
                </h1>
              </motion.h4>
              <motion.h5 variants={paragraphVariants}>
                I'm a Backend Engineer at{" "}
                <span style={{ color: "#48a3c6" }}>
                  Propelius Technologies!
                </span>
                .
              </motion.h5>
              <motion.div
                className="contentDescription"
                variants={staggerVariants}
                initial="initial"
                animate={inView ? "animate" : "initial"}
              >
                <motion.p variants={paragraphVariants}>
                  I began as a data scientist, skilled in data engineering,
                  MLOps, NLP, and AI. My journey evolved into mastering data
                  pipelines, warehousing, and backend technologies.{" "}
                  <span style={{ color: "white" }}>
                    I specialize in Node.js, Nest.js, Go, AWS, and databases
                    like PostgreSQL, MongoDB, MySQL, and GraphQL.
                  </span>{" "}
                  With dedication and curiosity, I've honed my skills over three
                  years, collaborating with diverse companies for best
                  practices.
                </motion.p>
                <br />
                <motion.p variants={paragraphVariants}>
                  Here, I proudly wear my{" "}
                  <span style={{ color: "white" }}> problem-solving </span>{" "}
                  cape, fearlessly embracing real-world challenges, all while I
                  pursue a degree in{" "}
                  <span style={{ color: "white" }}>
                    {" "}
                    Computer Science And Engineering{" "}
                  </span>{" "}
                  from{" "}
                  <span style={{ color: "white" }}>
                    KLE Technological University
                  </span>
                  . Amidst the dynamic dance of backend code and real-life
                  puzzles, I'm weaving my unique tapestry of triumph.
                </motion.p>
                <br />

                <motion.p variants={paragraphVariants}>
                  Throughout the entire journey so far, I haven't merely written
                  code for myself or any company. Instead, I've endeavored to
                  become an integral part of ideation, construction, deployment,
                  and the expansion of your product. My sole focus remains on
                  delivering a substantial experience alongside top-notch code
                  quality.
                </motion.p>
              </motion.div>
              <NavLink to="/portfolio">
                <Button name="View Portfolio" />
              </NavLink>{" "}
            </motion.div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutMe;
