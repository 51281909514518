import Header from "./components/Header";
import Footer from "./components/Footer";

import { useState } from "react";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";

import Landing from "./pages/landing/Landing";
import Portfolio from "./pages/portfolio/Portfolio";
import Resume from "./pages/resume/Resume";
import Contact from "./pages/contact/Contact";
import PageNotFound from "./pages/404/PageNotFound";
import Splash from "./components/Splash";
import Stats from "./pages/stats/Stats";
import DefaultLayout from "./layouts/DefaultLayout/DefaultLayout";

//blogs
import { PostDetail } from "./pages/blogs/PostDetail/PostDetail.tsx";
import { Home as HomeBlogs } from "./pages/blogs/Home/Home.jsx";
import ProjectDetails from "./pages/portfolio/ProjectDetails";

function App() {
  const personalDetails = {
    name: "Prakash Singh",
    location: "India",
    email: "prkskrs@gmail.com",
    availability: "Available",
    brand:
      "My unique blend of technical expertise, creative thinking, and background in psychology allows me to approach each project with a deep understanding of the end user's perspective, resulting in highly effective user-centred products.",
  };

  const location = useLocation();

  const [loading, setLoading] = useState(true);

  return (
    <>
      {loading ? (
        <Splash setLoading={setLoading} />
      ) : (
        <>
          <Header />
          <Routes location={location} key={location.pathname}>
            <Route
              path="/"
              element={
                <Landing
                  name={personalDetails.name}
                  tagline={personalDetails.tagline}
                />
              }
            />
            <Route path="/portfolio" element={<Portfolio />} />
            <Route
              path="/resume"
              element={<Resume brand={personalDetails.brand} />}
            />
            <Route
              path="/stats"
              element={<Stats brand={personalDetails.brand} />}
            />

            <Route
              path="/contact"
              element={
                <Contact
                  name={personalDetails.name}
                  location={personalDetails.location}
                  email={personalDetails.email}
                />
              }
            />
            <Route path="/page-not-found" element={<PageNotFound />} />
            <Route path="/portfolio/:projectTitle" element={<ProjectDetails />} />
            <Route path="/blogs/*" element={<DefaultLayout />}>
              <Route index element={<HomeBlogs />} />
              <Route path=":id" element={<PostDetail />} />
            </Route>
            <Route path="*" element={<Navigate to="/page-not-found" />} />
          </Routes>
          <Footer />
        </>
      )}
    </>
  );
}

export default App;
