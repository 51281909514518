import { Outlet } from "react-router-dom";
import HeaderBlogs from "../../components/HeaderBlogs/HeaderBlogs.jsx"; // Assuming HeaderBlogs is a default export.
import { LayoutContainer } from "./styles";

const DefaultLayout =()=> {
  return (
    
    <LayoutContainer>
      <HeaderBlogs />
      <Outlet />
    </LayoutContainer>
  );
}

export default DefaultLayout; // Export the component as default.
