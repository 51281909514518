export function formatText(text, limitLength = 50) {
  const textArr = text.split(" ");
  const newText = textArr
    .map((string, index) => {
      if (index < limitLength) return string;
      return null; // Return null for elements beyond the limit
    })
    .filter(string => string !== null); // Filter out the null values
  return `${newText.join(" ")}...`; // Use join instead of toString
}
